import React, {FC} from 'react';
import styles from '../../Admin/Admin.module.css'

interface MoreDotsProps {
    className: any,
}
const MoreDots: FC<MoreDotsProps> = () => {
    return (
        <div className={styles.editBtn}>
            <svg style={{margin: '0 0.1rem'}} width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1.5" cy="2" r="1.25" transform="rotate(-180 1.5 2)" fill="#909EBB" />
            </svg>
            <svg style={{margin: '0 0.1rem'}} width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1.5" cy="2" r="1.25" transform="rotate(-180 1.5 2)" fill="#909EBB" />
            </svg>
            <svg style={{margin: '0 0.1rem'}} width="3" height="4" viewBox="0 0 3 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1.5" cy="2" r="1.25" transform="rotate(-180 1.5 2)" fill="#909EBB" />
            </svg>
        </div>
    );
};

export default MoreDots;
