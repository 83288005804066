import React, {useEffect, FC} from 'react';
import styles from './Main.module.css';
import {Layout} from 'antd';
import {getAllUsers_Pagination} from "../store/adminAction";
import {useDispatch} from "react-redux";
import Auth from "./Auth/Auth";

const Main: FC = () => {
  const dispatch = useDispatch()
  const token = JSON.parse(localStorage.getItem('token') as string);
  const pages = { limit: 10, page: 1 }

  useEffect(() => {
    if (token) {
      getAllUsers_Pagination(token, '', undefined, pages)(dispatch)
    }
  }, [])

  return (
    <Layout className={styles.backgroundLayout}>
      <div className={styles.main}>
        <h1 className={styles.main_title}>Вход в Админ UniOne</h1>
        <Auth/>
      </div>
    </Layout>
  );
};

export default Main;
