import AdminService from '../services';
import { adminSlice } from "./adminSlice";
import { message } from "antd";
import { IPagination, SignInData } from '../interfaces/interfaces';
import { errorsKey } from '../utils';

type acceptDocumentArg = {
    user_id: number | string,
    comment: string,
    is_moderated: boolean,
};

export const signInModerator = ({ phone, email, password, navigate, pagination }: SignInData) => async (dispatch: any) => {
    const value: object = {
        phone,
        email,
        password
    }
    const response = await AdminService.$signInModerator(JSON.stringify(value));
    if (response && response.data.status !== false) {
        let tokenModerator = response.data.values[0];
        localStorage.setItem('token', JSON.stringify(tokenModerator));
        dispatch(getAllUsers_Pagination(tokenModerator, '', navigate, pagination))
    }
    if (response && response.data.status !== true) {
        const err = response.data.errors[0].message;
        message.error(err);
    }
}

// Получение списка пользователей
export const getAllUsers_Pagination = (token: string, query: string = '', navigate: any, pagination: IPagination) => async (dispatch: any) => {
    dispatch(adminSlice.actions.usersFetchingLoading());
    const paginate: object = {
        token,
        query,
        pages: {
           limit: pagination.limit,
           page: pagination.page
        },
    };
    const value: string = JSON.stringify(paginate);
    const response = await AdminService.$getAllUsers(value);

    if (response && response.data.status !== false) {
        let user = response.data.values[0];
        if (!user.count && user.count !== 0) {
            user = { users: [user], count: 1 }
        }
        dispatch(adminSlice.actions.usersFetchingSuccess(user))
        navigate && navigate('/admin');
    }
    if (response && response.data.status !== true) {
        const err = response.data.errors[0].message;
        dispatch(adminSlice.actions.usersFetchingError(err));
        message.error(err);
    }
}

export const acceptDocument = (data: acceptDocumentArg, fieldsInput:  string, pagination: IPagination) => async (dispatch: any) => {
    dispatch(adminSlice.actions.usersFetchingLoading());

    const token: string = JSON.parse(localStorage.getItem('token') || '{}')
    const paginate: object = { ...data, token };
    const response = await AdminService.$acceptDocument(JSON.stringify(paginate));

    if (response && response.data.status !== false) {
        if (data.is_moderated) {
            dispatch(adminSlice.actions.usersFetchingModerateSuccess(response.data))
        } else if (!data.is_moderated) {
            dispatch(adminSlice.actions.usersFetchingModerateError(response.data));
        }
        dispatch(getAllUsers_Pagination(token, fieldsInput, undefined, pagination))
    }

    if (response && response.data.status !== true) {
        const err = response.data.errors[0].key;
        const show = errorsKey(err);
        message.error(show);
    }
}
