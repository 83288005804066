import React, {FC, useState} from 'react';
import {Button, Col, Form, Input, Row} from "antd";
import styles from './Auth.module.css';
import {useDispatch} from "react-redux";
import {signInModerator} from '../../store/adminAction';
import {useNavigate} from 'react-router-dom';
import {SignInData} from '../../interfaces/interfaces';

const Anytime: FC = () => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const pages = { limit: 10, page: 1 }

    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const handlerClick = async () => {
        const data: SignInData = {
            phone: login,
            email: login,
            password: password,
            navigate: navigate,
            pagination: pages
        }
        if (login.includes('@')) {
            delete data.phone
        } else {
            delete data.email
        }
        await signInModerator(data)(dispatch)
    }

    return (
        <Row justify={"center"} align={"middle"}>
            <Col className={styles.colWidth}>
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="login"
                        rules={[{required: true, message: 'Пожалуйста, введите ваш логин!'}]}
                    >
                        <Input
                            allowClear
                            onChange={e => setLogin(e.target.value)}
                            placeholder='Введите номер телефонa или e-mail'
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: 'Пожалуйста, введите ваш пароль!'}]}
                    >
                        <Input.Password
                            allowClear
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="пароль"
                        />
                    </Form.Item>

                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                block
                                className={styles.buttonLogin}
                                disabled={!form.isFieldsTouched(true) || !!form.getFieldsError().filter(({errors}: any) => errors.length).length}
                                onClick={handlerClick}
                            >
                                Войти
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Anytime;