import { Button, Col, Input, Modal } from 'antd';
import React, { FC, useState } from 'react';
import styles from './Reject.module.css';
import { acceptDocument } from '../../../store/adminAction';
import { useDispatch } from 'react-redux';
import { RejectModalProps } from '../../../interfaces/interfaces';
import DocumentList from "../../DocumentList/DocumentList";


const Reject: FC<RejectModalProps> = ({ visible, value, setIsRejectModal, id, fieldsInput, pagination }) => {
    const [cause, setCause] = useState('')
    const dispatch = useDispatch()

    const handleCancel = () => {
        setIsRejectModal(false);
        setCause('')
    }
    const handleClickErr = () => {
        const data = {
            user_id: id,
            comment: cause,
            is_moderated: false,
        };
        setIsRejectModal(false);
        setCause('')
        acceptDocument(data, fieldsInput, pagination)(dispatch);
    }

    return (
        <Modal
            key={3}
            title="Отклонить документы"
            visible={visible}
            onCancel={handleCancel}
            className={styles.modalView}
            footer={[
                <>
                    <div className={styles._line_footer} />
                    <Button
                        type="ghost"
                        key="back"
                        className={styles.cancel}
                        onClick={handleCancel}
                    >
                        Отмена
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        className={styles.rejectBtn}
                        onClick={handleClickErr}
                    >
                        Отклонить документы
                    </Button>
                </>
            ]}
        >
            <div className={styles._line} />

            <Col className={styles.modal_col} span={24}>
                <h6>ФИО</h6>
                <h3>{value &&
                    value.user_info.passport.sur_name +
                    ' ' +
                    value.user_info.passport.name +
                    ' ' + value.user_info.passport.patronymic
                }</h3>
            </Col>

            <Col className={styles.modal_col} span={24}>
                <h6>Организация</h6>
                <h3>{value && value.user_info.work.place}</h3>
            </Col>

            {value &&
                <DocumentList value={value}/>
            }

            <div className={styles._line} />

            <Col span={24}>
                <h6>Причина отклонения документов</h6>
                <Input
                   placeholder="Выберите причину отклонения документов"
                   className={styles.modalInp}
                   onChange={(e: any) => setCause(e.target.value)}
                   value={cause}
                />
            </Col>
        </Modal>
    );
};

export default Reject;

