import { Modal } from 'antd';
import React from 'react';
import styles from './SuccessVerify.module.css'
import { CooliconIcon } from '../../assets/icons/CooliconIcon';
import Icon from "@ant-design/icons";

interface SuccessVerifyProps {
    visible: boolean,
    setisAcceptMessage: (is: boolean) => void,
}

const index:React.FC<SuccessVerifyProps> = ({ visible, setisAcceptMessage }) => {
    const handleCancel = () => {
        setisAcceptMessage(false);
    }

    return (
        <Modal
            visible={visible}
            footer={false}
            onCancel={handleCancel}
            className={styles.modalSuccess}
        >
            <div className={styles.modalSuccessWrapper}>
                <Icon component={CooliconIcon} className={styles.prefix} />
                <h1>
                    Аккаунт пользователя верифицирован
                </h1>

            </div>

        </Modal>
    );
};

export default index;