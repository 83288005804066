import React, {FC, useEffect, useMemo, useState} from 'react';
import styles from './Admin.module.css';
import {Dropdown, Empty, Input, Menu, Select, Tag} from 'antd';
import Icon from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {IsAdminIcon} from "../assets/icons/isAdminIcon";
import {SearchIcon} from "../assets/icons/SearchIcon";
import {getAllUsers_Pagination} from '../../store/adminAction';
import {AvatarIcon} from "../assets/icons/AvatarIcon";
import MoreDots from '../assets/icons/MoreDots';
import AcceptDocumentModal from '../Modal/AcceptDocument'
import ViewDocumentModal from '../Modal/ViewDocument'
import RejectDocumentModal from '../Modal/RejectDocument'
import SuccessVerifyModal from '../Modal/SuccessVerify'
import ErrorVerifyModal from '../Modal/ErrorVerify'

const Index: FC = () => {
    const [pagination, setPagination] = useState(10)
    const [counter, setCounter] = useState(1)
    const [countPagination, setCountPagination] = useState(1)
    const [changePrevAndNext, setChangePrevAndNext] = useState(false)
    const [prevCount, setPrevCount] = useState(countPagination)
    const [fieldsInput, setFieldsInput] = useState('')
    const [search, setSearch] = useState(false)

    const [isAcceptMessage, setisAcceptMessage] = useState(false);
    const [isErrorMessage, setisErrorMessage] = useState(false);
    const [isAcceptModal, setIsAcceptModal] = useState(false);
    const [isRejectModal, setIsRejectModal]: any = useState(false);
    const [isViewModal, setIsViewModal]: any = useState(false);
    const [userID, setUserID] = useState('');

    const Option = Select.Option;
    const {users, count, moderateSuccess, moderateError} = useSelector((state: any) => state.adminReducer);
    const dispatch = useDispatch();

    const BASE_BUCKET = 'basebucket'

    const token = JSON.parse(localStorage.getItem('token') || '{}');

    useEffect(() => {
        getAllUsers_Pagination(token, fieldsInput, undefined, { limit: Number(pagination), page: 1 })(dispatch)
    }, [])

    const changePageSizeForPagination = (e: any) => {
        getAllUsers_Pagination(token, fieldsInput, undefined, { limit: Number(e), page: 1 })(dispatch)
        setPagination(e)
        setCounter(1)
        setCountPagination(1)
        setSearch(false)
    }

    const arrowRight = () => {
        if (counter < Math.ceil(count / pagination)) {
            setCounter(counter + 1)
            setChangePrevAndNext(true)
            setCountPagination(countPagination + Number(pagination))
            getAllUsers_Pagination(token, fieldsInput, undefined, { limit: Number(pagination), page: counter + 1 })(dispatch)
        }
    }

    const arrowLeft = () => {
        if (counter > 1) {
            setCounter(counter - 1)
            setChangePrevAndNext(false)
            setPrevCount(countPagination)
            setCountPagination(countPagination - Number(pagination))
            getAllUsers_Pagination(token, fieldsInput, undefined, { limit: Number(pagination), page: counter - 1 })(dispatch)
        }
    }

    const searchUsers = (e: any) => {
        getAllUsers_Pagination(token, postPhoneFunc(toCapitalize(e.target.value)), undefined, { limit: Number(pagination), page: 1 })(dispatch)
        setFieldsInput(postPhoneFunc(toCapitalize(e.target.value)))
        setSearch(true)
        setCounter(1)
        setCountPagination(1)
    }

    const convertToThumbFormat = (url: string) => {
        const splitted = url.split('.');
        const miniatureImg = `${splitted[0]}_thumb.${splitted[1]}`;
        return `${process.env.REACT_APP_BASE_IMAGE_URL}/${BASE_BUCKET}/${miniatureImg}`;
    }

    const memoizedValue = useMemo(() => {
        let value
        if (changePrevAndNext) {
            if ((countPagination + (pagination - 1)) > count) {
                value = count
            } else {
                value = countPagination + (pagination - 1)
            }
        } else if (!changePrevAndNext) {
            if ((countPagination - (pagination - 1)) < 0) {
                if (pagination >= count) {
                    value = count || pagination
                } else {
                    value = pagination
                }
            } else {
                value = prevCount - 1
            }
        }

        if (users && search) {
            if (count < value || fieldsInput) {
                if ((countPagination + Number(pagination)) - 1 < count) {
                    return (countPagination + Number(pagination)) - 1
                } else {
                    return count
                }
            } else {
                if (value < pagination) {
                    return pagination
                } else {
                    return value
                }
            }
        } else if (!users) {
            return 0
        } else {
            return value
        }
    }, [changePrevAndNext, pagination, countPagination, fieldsInput, users])

    const statusLocalisation = (status: string) => {
        if (status === 'deleted') {
            return 'Удален'
        } else if (status === 'blocked') {
            return 'Деактивирован'
        } else {
            return 'Активен'
        }
    }

    const dateMask = (date: any) => {
        if (date[0] === '2') {
            const mask = /[\-]/g
            return date.substr(0, 10).replace(mask, '.')
        } else {
            return null
        }
    }

    const toCapitalize = (str: string) => {
        console.log(str, 'str');
        if (!str) return str;
        return str[0].toUpperCase() + str.slice(1);
    }

    const postPhoneFunc = (phone: any) => {
        const eight = '8'
        if (phone.substring(0, 2).includes(7)) {
            return eight + phone.replace(/[^+\d]/g, '').slice(2)
        } else {
            return phone
        }
    }

    useEffect(() => {
        if (moderateSuccess) {
            setisAcceptMessage(true);

            setTimeout(() => {
                setisAcceptMessage(false)
            }, 3000)
        }
    }, [moderateSuccess])

    useEffect(() => {
        if (moderateError) {
            setisErrorMessage(true);

            setTimeout(() => {
                setisErrorMessage(false);
            }, 3000)
        }
    }, [moderateError])

    const menu = (item: any) => (
        <Menu
            items={[
                {
                    key: 1,
                    label: (
                        <button
                            className={styles.actionBtn}
                            onClick={() =>
                                setIsViewModal({visible: true, value: item})
                            }
                        >
                            Посмотреть документы
                        </button>),
                },
                {
                    key: 2,
                    label: (<button
                        className={styles.actionBtn}
                        onClick={() => {
                            setIsAcceptModal(true)
                            setUserID(item.id)
                        }
                        }
                    >
                        Принять документы
                    </button>),
                }, {
                    key: 3,
                    label: (<button
                        className={styles.actionBtn}
                        onClick={() =>
                            setIsRejectModal({visible: true, value: item})
                        }
                    >
                        Отклонить документы
                    </button>),
                }
            ]}
        />
    )

    return (<div className={styles._body}>
        <div className={styles._bodyHeader}>
            <div className={styles._bodyHeaderChildBlock}>
                <div className={styles._listUser}>Список пользователей для верификации</div>
            </div>
        </div>

        <ViewDocumentModal
            key={1}
            visible={isViewModal.visible}
            value={isViewModal.value}
            setIsViewModal={setIsViewModal}
        />

        <RejectDocumentModal
            key={3}
            visible={isRejectModal.visible}
            value={isRejectModal.value}
            setIsRejectModal={setIsRejectModal}
            // setisErrorMessage={setisErrorMessage}
            id={userID}
            fieldsInput={fieldsInput} 
            pagination={{ limit: Number(pagination), page: counter }}
        />

        <AcceptDocumentModal
            key={2}
            visible={isAcceptModal}
            setIsAcceptModal={setIsAcceptModal}
            id={userID}
            fieldsInput={fieldsInput} 
            pagination={{ limit: Number(pagination), page: counter }}
        />

        <SuccessVerifyModal
            setisAcceptMessage={setisAcceptMessage}
            visible={isAcceptMessage}
        />

        <ErrorVerifyModal
            setisErrorMessage={setisErrorMessage}
            visible={isErrorMessage}
        />

        <Input
            className={styles._input}
            placeholder={' Начните вводить текст для поиска'}
            prefix={<Icon component={SearchIcon} className={styles.prefix}/>}
            onPressEnter={searchUsers}
        />

        <div className={styles._tableBlock}>
            <table className={styles._table}>
                <thead>
                <tr className={styles.tableThead}>
                    <th>ID</th>
                    <th>Аватар</th>
                    <th>ФИО</th>
                    <th>Организация</th>
                    <th>E-mail</th>
                    <th>Статус</th>
                    <th>Дата документов</th>
                    <th>Верифицирован</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {users.length
                    ? (
                        users.map((item: any, index: number) => (
                            <tr
                                key={item.id + index}
                                className={styles.tableTbody}
                            >
                                <td>{item.id}</td>

                                <td className={styles.avatarBlock}>
                                    {item.avatar ? <img src={convertToThumbFormat(item.avatar)} alt="user_avatar"/> :
                                        <Icon component={AvatarIcon} className={styles.avatarIcon}/>}
                                </td>

                                <td className={styles.passportBlock}>
                                    <span>
                                        {item.user_info.passport.sur_name + " "}
                                        {item.user_info.passport.name + " "}
                                        {item.user_info.passport.patronymic}
                                    </span>

                                    {item.roles[0]?.role === 'admin' && <Icon component={IsAdminIcon} className={styles.ellipseDrots}/>}
                                </td>

                                <td>
                                    {item.roles.map((user_class: any, index: number) => (
                                        <div key={user_class.role + index}>
                                            <div>{user_class.role}</div>
                                            <Tag className={user_class.service ? styles.tags : styles.tagsNone}
                                                 key={`id_key_${index}`}>
                                                {user_class.service}
                                            </Tag>
                                        </div>))}
                                </td>

                                <td>{item.email}</td>

                                <td
                                    className={item.status === 'active' ? styles.activeStatus : item.status === 'deleted' ? styles.deletedStatus : styles.blockedStatus}
                                >
                                    {statusLocalisation(item.status)}
                                </td>

                                <td>{dateMask(item.created_at)}</td>

                                <td>{item.moderation.is_moderated ? 'Да' : 'Нет'}</td>

                                <td className={styles.columnRight}>
                                    <Dropdown overlay={menu(item)} placement="bottomRight">
                                        <div>
                                            <MoreDots
                                                className={styles.editBtn}
                                            />
                                        </div>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                    )
                    : (
                        <tr key={12}>
                            <td colSpan={10}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>

        <div className={styles.tableFooter}>
            <div className={styles.record}>Всего записей: {count}</div>
            <div className={styles.tableFooterChildBlock}>
                <div className={styles.select}>На странице по :
                    <Select defaultValue={"10"} onChange={changePageSizeForPagination}>
                        <Option value="5">5</Option>
                        <Option value="10">10</Option>
                        <Option value="20">20</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                    </Select>
                </div>
                <div className={styles.paginationCounter}>
                    {countPagination}-{memoizedValue}
                    <span> из </span> {count}
                </div>
                <div className={styles.arrow}>
                    <p><i className={styles.arrow_left} onClick={arrowLeft}/></p>
                    <p><i className={styles.arrow_right} onClick={arrowRight}/></p>
                </div>
            </div>
        </div>
    </div>);
};

export default Index;
