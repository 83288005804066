import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import Admin from '../components/Admin';
import Main from '../components/Main';

const Index: FC = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Main />} />
            <Route path={'admin'} element={<Admin />}  />
        </Routes> 
    );
};

export default Index;