import React, { FC } from 'react';
import Routers from './Routers';

const App: FC = () => {
  return (
    <div>
      <Routers />
    </div>
  );
}

export default App;
