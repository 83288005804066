import axios from "axios";

interface endpoints {
    getAllUsers: string | undefined,
    deactivateUser: string | undefined,
    deleteUser: string | undefined,
    signInModerator: string | undefined,
    moderateUser: string | undefined,
}
const base_url: any = process.env.REACT_APP_ADMIN_BASE_URL

const endpoints: endpoints = {
    getAllUsers: process.env.REACT_APP_ADMIN_GET_ALL_USERS,
    deactivateUser: process.env.REACT_APP_ADMIN_DEACTIVE_USER,
    deleteUser: process.env.REACT_APP_ADMIN_DELETE_USER,
    signInModerator: process.env.REACT_APP_ADMIN_SIGN_IN,
    moderateUser: process.env.REACT_APP_ADMIN_MODERATE
}

export default class ProfileService {
    static async $getAllUsers(data: string) {
        return await axios.post(base_url + endpoints.getAllUsers, data)
    }
    
    static async $signInModerator(data: string) {
        return await axios.post(base_url + endpoints.signInModerator, data)
    }
    
    static async $acceptDocument(data: string) {
        return await axios.post(base_url + endpoints.moderateUser, data)
    }
}