import { Modal } from 'antd';
import React, { FC } from 'react';
import { ErrorMessageProps } from '../../../interfaces/interfaces';
import styles from './ErrorVerify.module.css'

const index: FC<ErrorMessageProps> = ({ visible, setisErrorMessage }) => {
    const handleCancel = () => {
        setisErrorMessage(false);
    }
    return (
        <Modal
            visible={visible}
            footer={false}
            onCancel={handleCancel}
            className={styles.modalSuccess}
        >
            <div className={styles.modalSuccessWrapper}>
                <h1>
                    Аккаунт пользователя НЕ верифицирован
                </h1>
            </div>
        </Modal>
    );
};

export default index;