import { Button, Modal } from 'antd';
import React, { FC } from 'react';
import styles from './Accept.module.css';
import Icon from "@ant-design/icons";
import Warning from '../../assets/icons/Warning'
import { useDispatch } from 'react-redux';
import { acceptDocument } from '../../../store/adminAction';
import { AcceptModalProps } from '../../../interfaces/interfaces';

const Accept: FC<AcceptModalProps> = ({ visible, setIsAcceptModal, id, fieldsInput, pagination }) => {
    const dispatch = useDispatch()
    const handleCancel = () => {
        setIsAcceptModal(false);
    }

    const handleAcceptDocument = () => {
        const data = {
            user_id: id,
            comment: 'qweqweq qwe qwe',
            is_moderated: true
        }
        setIsAcceptModal(false);
        acceptDocument(data, fieldsInput, pagination)(dispatch)
    }

    return (
        <Modal
            key={2}
            title="Принять документы"
            visible={visible}
            onCancel={handleCancel}
            className={styles.modalView}
            footer={[
                <div key={321}>
                    <div className={styles._line_footer} />
                    <Button
                        type="ghost"
                        key="back"
                        className={styles._modalBtnCalcel}
                        onClick={handleCancel}
                    >
                        Отмена
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        className={styles.save}
                        onClick={handleAcceptDocument}
                    >
                        Принять документы
                    </Button>
                </div>
            ]}
        >
            <div className={styles._line} />

            <Icon
                component={Warning}
                className={styles.phoneBlockIcon}
            />
            <p>
                Вы уверены, что документы, прикрепленные пользователем, корректны?
            </p>

        </Modal>
    );
};

export default Accept;

