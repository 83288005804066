import { Col, Modal } from 'antd';
import React, { FC } from 'react';
import styles from './ViewDocument.module.css';
import { currentUser } from '../../../interfaces/interfaces';
import DocumentList from "../../DocumentList/DocumentList";

interface ViewDocumentProps {
    visible: any,
    setIsViewModal: (is: boolean) => void,
    value: currentUser
}

const ViewDocument: FC<ViewDocumentProps> = ({ visible, setIsViewModal, value }) => {
    const handleCancel = () => {
        setIsViewModal(false);
    }
    return (
        <Modal
            title="Документы"
            visible={visible}
            onCancel={handleCancel}
            footer={false}
            className={styles.modalView}
        >
            <div className={styles._line} />

            <Col className={styles.modal_col} span={24}>
                <h6>ФИО</h6>
                <h3>{ value &&
                    value.user_info.passport.sur_name +
                    ' ' +
                    value.user_info.passport.name +
                    ' ' + value.user_info.passport.patronymic
                }</h3>
            </Col>

            <Col className={styles.modal_col} span={24}>
                <h6>Организация</h6>
                <h3>{ value && value.user_info.work.place}</h3>
            </Col>

            {value &&
                <DocumentList value={value}/>
            }

        </Modal>
    );
};

export default ViewDocument;

