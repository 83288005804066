import React, {FC} from 'react';
import {Col} from "antd";
import styles from "../Modal/RejectDocument/Reject.module.css";
import {FileZipOutlined} from "@ant-design/icons";
import {currentUser} from "../../interfaces/interfaces";

interface DocumentLIstProps {
    value: currentUser,
}

const DocumentList: FC<DocumentLIstProps> = ({value}) => {
    console.log(value, 'valueKey')
    const BASE_BUCKET = 'basebucket'

    const documentUrl = (url: string) => {
        return `${process.env.REACT_APP_BASE_IMAGE_URL}/${BASE_BUCKET}/${url}`;
    }

    return (
        <div>
            {value.user_info.docs.images.passport?.map((file) => (
                <Col span={24} key={file.image}>
                    <div className={styles.workspaceDocument}>
                        <div className={styles.workspaceDocument__inner}>
                            <FileZipOutlined className={styles._marginRight}/>
                            <a href={documentUrl(file.image)}>
                                <h4 className={styles.blueText_WithIcon}>{file.name}</h4>
                            </a>
                        </div>
                    </div>
                </Col>
            ))}

            {value.user_info.docs.images.diploma?.map((file) => (
                <Col span={24} key={file.image}>
                    <div className={styles.workspaceDocument}>
                        <div className={styles.workspaceDocument__inner}>
                            <FileZipOutlined className={styles._marginRight}/>
                            <a href={documentUrl(file.image)}>
                                <h4 className={styles.blueText_WithIcon}>{file.name}</h4>
                            </a>
                        </div>
                    </div>
                </Col>
            ))}

            {value.user_info.docs.images.qualification?.map((file) => (
                <Col span={24} key={file.image}>
                    <div className={styles.workspaceDocument}>
                        <div className={styles.workspaceDocument__inner}>
                            <FileZipOutlined className={styles._marginRight}/>
                            <a href={documentUrl(file.image)}>
                                <h4 className={styles.blueText_WithIcon}>{file.name}</h4>
                            </a>
                        </div>
                    </div>
                </Col>
            ))}
        </div>
    );
};

export default DocumentList;
