import { createSlice } from "@reduxjs/toolkit";

type initialState = {
    users: [],
    users_length: number,
    isLoading: boolean,
    errors: string,
    count: number | null,
    moderateError: boolean,
    moderateSuccess: boolean,
}   

const initialState: initialState = {
    users: [],
    users_length: 0,
    isLoading: false,
    errors: '',
    count: null,
    moderateError: false,
    moderateSuccess: false,
}

export const adminSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        usersFetchingLoading (state: initialState) {
            state.isLoading = true;
        },
        usersFetchingSuccess (state: initialState, action) {
            state.isLoading = false;
            state.errors = '';
            state.users = action.payload.users || [];
            state.count = action.payload.count
        },
        usersFetchingError (state: initialState, action) {
            state.isLoading = false;
            state.errors = action.payload;
        },
        usersFetchingModerateSuccess (state: initialState, action) {
            state.moderateSuccess = action.payload
        },
        usersFetchingModerateError (state: initialState, action) {
            state.moderateError = action.payload
        }
    }
})

export default adminSlice.reducer;