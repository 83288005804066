import React, { FC } from 'react';

export const AvatarIcon: FC = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="36" height="36" rx="18" fill="#F5FBFF"/>
    <path
      d="M21.3327 13.8333C21.3327 14.7174 20.9815 15.5652 20.3564 16.1904C19.7313 16.8155 18.8834 17.1667 17.9993 17.1667C17.1153 17.1667 16.2674 16.8155 15.6423 16.1904C15.0172 15.5652 14.666 14.7174 14.666 13.8333C14.666 12.9493 15.0172 12.1014 15.6423 11.4763C16.2674 10.8512 17.1153 10.5 17.9993 10.5C18.8834 10.5 19.7313 10.8512 20.3564 11.4763C20.9815 12.1014 21.3327 12.9493 21.3327 13.8333V13.8333ZM17.9993 19.6667C16.4523 19.6667 14.9685 20.2812 13.8746 21.3752C12.7806 22.4692 12.166 23.9529 12.166 25.5H23.8327C23.8327 23.9529 23.2181 22.4692 22.1241 21.3752C21.0302 20.2812 19.5464 19.6667 17.9993 19.6667V19.6667Z"
      stroke="#99C6EB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)
